<template>
  <AuthLayout>
    <main>
      <div class="container">
        <div style="margin-top: 15vh" class="row justify-content-center">
          <div class="col-lg-7">
            <div class="card shadow-lg border-0 rounded-lg mt-5">
              <div class="card-header justify-content-center">
                <h3 class="font-weight-light my-4">Change Password</h3>
              </div>
              <div class="card-body">
                <div class="small mb-3 text-muted">
                  <p v-if="otpStep == 1">
                    Enter your email address and we will send you an otp to
                    reset your password.
                  </p>
                  <p v-if="otpStep == 2">
                    Enter your new password, and the OTP that was sent to your
                    email address
                  </p>
                </div>
                <form
                  v-if="otpStep == 1"
                  @submit.prevent="forgotPassword(data)"
                >
                  <div class="form-group">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email</label
                    ><input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      v-model="data.email"
                      type="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div
                    class="
                      form-group
                      d-flex
                      align-items-center
                      justify-content-between
                      mt-4
                      mb-0
                    "
                  >
                    <router-link class="small" to="/login"
                      >Return to login</router-link
                    >
                    <button v-if="loading" class="btn btn-primary disabled">
                      <div
                        class="spinner-border spinner-border-sm mx-1 text-light"
                        role="status"
                      ></div>
                      Loading...
                    </button>
                    <button v-else type="submit" class="btn btn-primary">
                      Reset Password
                    </button>
                  </div>
                </form>
                <form
                  v-if="otpStep == 2"
                  @submit.prevent="changePassword(data)"
                >
                  <div class="form-group">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email</label
                    ><input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      type="email"
                      v-model="data.email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email address"
                      disabled
                    />
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6">
                      <label class="small mb-1" for="password"
                        >Enter New Password</label
                      ><input
                        class="form-control py-4"
                        v-model="data.password"
                        id="password"
                        type="password"
                        aria-describedby="password"
                        placeholder="Enter Password"
                      />
                    </div>
                    <div class="form-group col-6">
                      <label class="small mb-1" for="cpassword"
                        >Confirm New Password</label
                      ><input
                        class="form-control py-4"
                        v-model="data.confirmPassword"
                        id="cpassword"
                        type="password"
                        aria-describedby="cpassword"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="otp">Enter OTP</label
                    ><input
                      class="form-control py-4"
                      v-model="data.otp"
                      id="otp"
                      type="otp"
                      aria-describedby="otp"
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div
                    class="
                      form-group
                      d-flex
                      align-items-center
                      justify-content-between
                      mt-4
                      mb-0
                    "
                  >
                    <router-link
                      @click.prevent="forgotPassword(data)"
                      class="small"
                      to="#"
                      >Resend OTP</router-link
                    >
                    <button v-if="loading" class="btn btn-primary disabled">
                      <div
                        class="spinner-border spinner-border-sm mx-1 text-light"
                        role="status"
                      ></div>
                      Loading...
                    </button>
                    <button v-else type="submit" class="btn btn-primary">
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center">
                <div class="small">
                  <router-link v-if="otpStep == 2" class="" to="/login"
                    >Return to login</router-link
                  >
                  <router-link v-else class="" to="#"
                    >Dont have an account ? Create One</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AuthLayout>
</template>

<script>
import AuthLayout from "../layouts/AuthLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMainStore } from "../stores/main";
import { useAuthStore } from "../stores/authentication";

export default {
  name: "ForgotPassword",
  components: { AuthLayout },
  data() {
    return {
      toggleNav: false,
      data: {
        email: "",
        otp: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    ...mapState(useMainStore, ["isLoggedIn"]),
    ...mapState(useAuthStore, ["loading", "otpStep"]),
  },

  methods: {
    ...mapActions(useAuthStore, ["forgotPassword", "changePassword"]),
  },
};
</script>

<style>
</style>
